/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/style.css';
import '../css/jquery-ui.css';
import '../css/jquerymobile.css';
import '../css/mobiledatepicker.css';
import '../css/skin/scheme1.css';


// start the Stimulus application
//import '../bootstrap';
// loads the jquery package from node_modules
//var $ = require('jquery');
//$(document).ready(function() {
 // $('body').prepend('<h1>'+greet('Miljill')+'</h1>');
 // console.log('message');
//});
// import the function from greet.js (the .js extension is optional)
// ./ (or ../) means to look for a local file

//var cookieconsent = require('./cookieconsent');
//var cookies = require('./cookies');
//var modernizr = require("./modernizr");
//var googletracking = require('./googletracking');
var html5 = require('./html5');
var jqueryui = require('./jquery-ui');
var jqueryform = require('./jquery.form');
var mynavigation = require('./mynavigation');
//var overlay = require('./overlay');
var youtubeplayer_artist_page = require('./youtubeplayer_artist_page');


