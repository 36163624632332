$(document).ready(function () {
  $("#video1,#video2,#video3").click(function () {
    $("#video1,#video2,#video3").css("opacity","1.0");
    $(".regulation-youtube").show();
    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
      player.cueVideoById($(this).attr('track'));
    } else {
      player.loadVideoById($(this).attr('track'));
    }
    $( this ).css("opacity","0.6");
    $(this).find(".regulation-youtube").hide();
  });
});

var tag = document.createElement('script');
tag.src = "https://www.youtube.com/player_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

var player;

window.onYouTubePlayerAPIReady = function() {

  player = new YT.Player(theplayer,
    {
      events: {
        // call this function when player is ready to use
        'onReady': onPlayerReady
      }
    });
}

function stopVideo() {
  player.stopVideo();
}

function onPlayerReady() {
  var value1 = $("#video1").attr('track');
  player.cueVideoById(value1);
}

function playVideo(vid_id) {
  player.loadVideoById(vid_id);
}


/*
$('.close').on('click', function () {
  stopVideo();
  closeOverlay('#overlayInABox');
})

$("#searchshow").click(function () {
  $(".searchbox").show("slow");
  $("#hero").hide("slow");
});
*
 */

