                    //disable buttons
                    $(document).ready(function() {
                        $("#tomorrowForm").show();
                        $("#todayForm").show();
                        $("#getCoordPosition").show();
                         $("#searchshow").show();

                    });

                    // date picker

                    $(function() {
                    var date = $('#datepickerstart').datepicker({ dateFormat: 'dd M yy', minDate:0 }).val();
                     });

                    $(function() {

                    var date =  $( "#datepickerend" ).datepicker({
                        dateFormat: 'dd M yy',
                        minDate:0,
                        onSelect: function(date){
                        var date1 = $('#datepickerstart').datepicker('getDate');
                        var date = new Date( Date.parse( date1 ) );
                        date.setDate( date.getDate());
                        var newDate = date.toDateString();
                        newDate = new Date( Date.parse( newDate ) );
                        $('#datepickerend').datepicker("option","minDate",newDate);

                        }

                        }).val();

                    });

                     // below could be a hack, I have no way of getting the url created by twig other than putting in hidden field

                     var eventcontroller = $('#eventcontroller').val();
                      // dates selected need to be passed back in search
                     var stdate = {request : $('#stdate').val()};
                     var edate = {request : $('#edate').val()};
                     var city_id = {request: $('#city_id').val()};
                      if($('#edit').val())  {
                          var edit = $('#edit').val();
                      };

                     // spinner for search overlay
                      $(document).ready(function() {

                      $( document ).ajaxStart(function() {
                      $( ".log" ).addClass('loading');
                      });
                       $( document ).ajaxStop(function() {
                      $( ".log" ).removeClass('loading');
                      });

                      $('#myForm').submit(function() {
                        if ($.trim($("#datepickerstart,#datepickerend").val()) === "") {
                              alert('Please fill out both search dates');
                            return false;
                        }
                     });

                      $('#myForm').ajaxForm( {

                      url: eventcontroller,
                      data: {
                      edit:edit,
                      stdate:stdate,
                      edate:edate,
                      city_id:city_id,
                      pageno:0,
                      artistpage:'no'
                         },
                       type:"POST",
                     success: function(response)
                     {

                     $("#mydiv").html(response);
                     },

                     error: function(XMLHttpRequest, textStatus, errorThrown)
                     {
                    $("#mydiv").html(errorThrown);
                     }
                      });
                      });

                        // tomorrow , today etc function
                  $(document).ready(function() {
                      $("#tomorrowForm").click(function() {
                      $.ajax({
                      url: eventcontroller,
                      data: {
                      edit:edit,
                      artistpage:'no',
                      pageno:1,
                      day:$(this).text()
                                         },
                      type:"POST",

                     success: function(response)
                     {
                     $("#mydiv").html(response);
                     },
                     error: function(XMLHttpRequest, textStatus, errorThrown)
                     {
                    $("#mydiv").html(errorThrown);
                     }

                    });
                   });
                  });


                  // tomorrow , today etc function
                  $(document).ready(function() {
                      $("#todayForm").click(function() {
                      $.ajax({
                      url: eventcontroller,
                      data: {
                      edit:edit,
                      artistpage:'no',
                      pageno:1,
                      day:$(this).text()
                                         },
                      type:"POST",

                     success: function(response)
                     {
                     $("#mydiv").html(response);
                     },
                     error: function(XMLHttpRequest, textStatus, errorThrown)
                     {
                    $("#mydiv").html(errorThrown);
                     }


                       });
                   });
                  });
                    // get co ordinates
               $(document).ready(function() {

                   $("#getCoordPosition").click(function() {

                            if (Modernizr.geolocation) {
                             navigator.geolocation.getCurrentPosition(success, error,{timeout:3000} );
                              alert(position.coords.latitude);
                             }

                             function success(position) {



                                           $.ajax({
                                           url: eventcontroller,
                                           data: {
                                           artistpage:'no',
                                           pageno:0,
                                           lat:position.coords.latitude,
                                           long:position.coords.longitude
                                                              },
                                           type:"POST",

                                            success: function(response)
                                            {
                                            $("#mydiv").html(response);
                                            },
                                            error: function(XMLHttpRequest, textStatus, errorThrown)
                                            {
                                           $("#mydiv").html(errorThrown);
                                            }


                                            });

                              }

                             function error(err) {
                             if(err.code == 1) {
                              alert("Sorry browser refuses lat and long access, please use other search methods");
                              }else if( err.code == 2) {
                             alert("Error: Position is unavailable!");
                             }
                            }
                   });
                  });

                   // click through to ticket

                   $("#tickets").click(function() {
                     var stdate = $(this).attr('start');
                     var edate = $(this).attr('end');
                     var ticketscontroller = $('#ticketscontroller').val();
                     var camefrom = 'artist-page';
                     var concertdate = $(this).attr('concertdate');
                     var venue = $(this).attr('venue');
                     var artist = $(this).attr('artist');
                     var useraction = 'tickets';
                     var videoid = 'none';
                     var has_video = $(this).attr('has_video');
                   $.ajax({
                   url: ticketscontroller,
                   data: {camefrom:camefrom,
                     concertdate:concertdate,
                     venue:venue,
                     artist:artist,
                     videoid:videoid,
                     hasvideo:has_video,
                     start:stdate,
                     end:edate,
                     useraction:useraction},
                   type:"POST",
                    success: function(response)
                     {
                     $("#mydiv").html(response);
                     },
                     error: function(XMLHttpRequest, textStatus, errorThrown)
                     {
                    $("#mydiv").html(errorThrown);
                     }


                       });
                   });


                  // pagination and date combined
                  $(document).ready(function() {

                  var value1 = $('#val1').val();
                  var value2 = $('#val2').val();

                  $("#navpage1,#navpage2,#navpage3").click(function() {

                  $.ajax({
                   url: eventcontroller,
                   data: {
                   edit:edit,
                   stdate:value1,
                   edate:value2,
                   artistpage:'no',
                   pageno:$(this).attr('pageno')
                   },
                   type:"POST",
                  success: function(response)
                  {
                  $("#mydiv").html(response);
                   },
                 error: function(XMLHttpRequest, textStatus, errorThrown)
                 {
                $("#mydiv").html(errorThrown);
                  }
                 });

                });

                });


                // remove video is does not match
                   $(document).ready(function() {

                   var removevideo = 'removevideo';
                    // for pagination

                   $("#removevideo1,#removevideo2,#removevideo3,#removevideo4,#removevideo5,#removevideo6,#removevideo7,#removevideo8,#removevideo9,#removevideo10").click(function() {

                    var artist = $(this).attr('artisttitle');
                    var thestatus = $(this).attr('status');
                   $.ajax({
                   url: removevideo,
                   data: {video:artist,
                              artistpage:'no',
                              status:thestatus
                             },
                   type:"POST",
                  success: function(response)
                  {
                  $("#blacklistresponse").html(response);

                   },
                 error: function(XMLHttpRequest, textStatus, errorThrown)
                 {
                $("#blacklistresponse").html(errorThrown);

                  }


                 });

                });

                });


                 // add video if match
                   $(document).ready(function() {

                    // for pagination

                   $("#addvideo1,#addvideo2,#addvideo3,#addvideo4,#addvideo5,#addvideo6,#addvideo7,#addvideo8,#addvideo9,#addvideo10").click(function() {


                    var artist = $(this).attr('artisttitle');
                    var thestatus = $(this).attr('status');
                   $.ajax({
                   url: removevideo,
                   data: {video:artist,
                              artistpage:'no',
                              status:thestatus
                             },
                   type:"POST",
                  success: function(response)
                  {
                  $("#blacklistresponse").html(response);
                   },
                 error: function(XMLHttpRequest, textStatus, errorThrown)
                 {
                $("#blacklistresponse").html(errorThrown);
                  }
                 });

                });

                });

                    /* Add click event to expand anchor */
                    $("li > a.expand").click( function() {

                        /* Add .expand class to parent */
                        $(this).parent().addClass("expand");

                    });

                    // Add click event to collapse anchor
                    $("li > a.collapse").click( function()  {

                        // Remove .expand class from parent
                        $(this).parent().removeClass("expand");

                    });


                    // remind me link on event page
                    $("#remindme").click(function() {
                        var concertdate = $(this).attr('data-concertdate');
                        var venue = $(this).attr('data-venue');
                        var city = $(this).attr('data-city');
                        var artistname = $(this).attr('data-artistname');
                        var link = $(this).attr('data-link');

                        $.ajax({
                            url: '../remindme',
                            data: {
                                link: link,
                                concertdate: concertdate,
                                venue: venue,
                                city: city,
                                artistname: artistname
                            },
                            type:"POST",
                            success: function(response)
                            {
                                alert('jojo');
                                $("#remindme").html(response);
                                window.location.href="https://concertflow.com/register";

                            },
                            error: function(XMLHttpRequest, textStatus, errorThrown)
                            {
                                $("#remindme").html(errorThrown);
                            }


                        });
                    });
